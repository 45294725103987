import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import github from '../img/social/github.svg'
import linkedin from '../img/social/linkedin.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter">
        <div className="content has-text-centered has-text-white-ter">
          <div className="container has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
